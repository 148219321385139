.row-gallery {
    margin-right: -7px;
    margin-left: -7px;

    .zoom {
        @include media-breakpoint-up(xs) {
            @include col-custom(1);
        }

        @media (min-width: 400px) {
            @include col-custom(2);
        }

        @include media-breakpoint-up(sm) {
            @include col-custom(3);
        }

        @media (min-width: 650px) {
            @include col-custom(2);
        }

        @include media-breakpoint-up(md) {
            @include col-custom(2);
        }

        @media (min-width: 930px) {
            @include col-custom(3);
        }

        img {
            width: 100%;
            margin: 0 !important;
        }
    }
}
