.mark {
    &__toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 2px;
        transition: transform 0.5s;
        width: 1.25em;
        height: 1.25em;

        &--active {
            transform: rotate(45deg);
        }

        &::before,
        &::after {
            position: absolute;
            width: 100%;
            height: 4px;
            background: #fff;
            content: "";
        }
        &::after {
            transform: rotate(90deg);
        }
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}
