#search {
    margin-bottom: 10px;

    @include media-breakpoint-up(xs) {
        width: calc(100% - 50px);
        margin-right: auto;
    }
    @media (min-width: 480px) {
        max-width: 350px;
        width: 100%;
        margin-right: inherit;
    }
}
#search form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
#search .input_search {
    max-height: 25px;
    font-size: 12px;
    border: 1px solid #b0b0b0;
    display: flex;
    padding: 5px;
}
#search .button_search {
    flex: 0 0 32px;
    max-width: 32px;
    height: 28px;
    background-position-y: center;
    background: url(../images/search.png) no-repeat;
    border-style: none;
    cursor: pointer;
    cursor: hand;
    padding: 0;

    @include media-breakpoint-down(sm) {
        filter: grayscale(1);
    }
}
