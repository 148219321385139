.u-margin-minus-30 {
    @include media-breakpoint-up(xl) {
        margin-left: -30px;
        margin-right: -30px;
    }
}

.u-margin-minus-7 {
        margin-left: -7px;
        margin-right: -7px;
}
