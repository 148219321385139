/* стили для отображения таблиц популярных товаров */
.populars {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.populars a {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up (xs) {
        @include col-custom(1);
    }

    @media (min-width: 400px) {
        @include col-custom(2);
    }

    @include media-breakpoint-up (sm) {
        @include col-custom(3);
    }

    @include media-breakpoint-up (md) {
        @include col-custom(2);
    }

    @media (min-width: 930px) {
        @include col-custom(3);
    }

    @include media-breakpoint-up (lg) {
        @include col-custom(4);
    }
    
}

.populars p {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
}

.populars > p {
    flex: 0 0 100%;
    max-width: 100%;
}

/* окончание описания стилей для таблиц популярных товаров */
