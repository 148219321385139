.side-bar {
    @include media-breakpoint-down(sm) {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        padding: 20px;
        z-index: -1;
        overflow: scroll;
        opacity: 0;
        transition: opacity 0.5s;
    }

    a, .catalog_menu__link {
        @include media-breakpoint-down(sm) {
            color: #fff;
        }
    }

    &.active {
        opacity: 1;
        z-index: 1;
    }
}

.position {
    cursor: pointer;
    margin-right: 20px;
}

.position-active {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 2;
    margin: 0;
}

#catalog_menu {
    li {
        @include media-breakpoint-down(sm) {
            //padding-left: 30px;
        }
    }

    .parent {
        position: relative;

        > ul {
            @include media-breakpoint-down(sm) {
                padding-left: 0;
                //margin-bottom: 15px;
            }
        }

        &--open {
            > ul {
                
                //display: block !important;
            }
        }
    }

    .currentitem {
        position: relative;
    }
}
