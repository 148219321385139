.headings-h1,
.headings-h2,
.headings-h3 {
    font-weight: normal;
}

.headings-h1 {
    margin-bottom: 10px;
    font-size: $h1-size;
    /* @include media-breakpoint-up(xs) {
        font-size: 1.225em;
    }
    @include media-breakpoint-up(sm) {
        font-size: $h2-size;
    }
    @include media-breakpoint-up(md) {
        font-size: 80px;
    } */
}

.headings-h2 {
	font-size: 20px;
	margin-bottom: 10px;
    font-size: $h2-size;
    /* @include media-breakpoint-up(xs) {
        font-size: 1.225em;
    }
    @include media-breakpoint-up(sm) {
        font-size: $h2-size;
    } */
}

.headings-h3 {
	margin-top: 0px;
	margin-bottom: 10px;
    font-size: $h3-size;
}

h1 {
    @extend .headings-h1;
}
h2 {
    @extend .headings-h2;
}
h3 {
    @extend .headings-h3;
}
