#catalog_menu {
    margin-top: 10px;
    margin-bottom: 10px;
}
#catalog_menu ul {
    padding-left: 0px;
    padding-bottom: 5px;
    list-style: none;
    line-height: 1.15;
    margin: 0;
}
#catalog_menu ul ul {
    //padding-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
}
#catalog_menu ul li {
    font-size: 16px;
    margin-top: 10px;
    @include media-breakpoint-down(sm) {
        letter-spacing: 0.06em;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        line-height: 1.5;
    }
}
#catalog_menu a,
#catalog_menu .catalog_menu__link {
    @include media-breakpoint-down(sm) {
        padding-left: 25px;
        display: block;
    }
}
#catalog_menu ul li img {
    vertical-align: middle;
}
#catalog_menu ul li a.selected,
#catalog_menu .catalog_menu__link.selected {
    color: #ffffff;
    background-color: #0095eb;
    text-decoration: none;
    @include media-breakpoint-down(sm) {
        background: none;
        font-style: italic;
    }
}
#catalog_menu ul ul li {
    font-size: 14px;
    padding-left: 12px;
}
#catalog_menu ul ul ul li {
    font-size: 12px;
}

#catalog_menu .parent ul {
    display: none;
}

#catalog_menu .currentparent ul {
    display: block;
}
