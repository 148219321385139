.user-content {
    h1 {
        @extend .headings-h2;
        text-align: left;
    }
    p,ul {
        margin-bottom: 1.412em;
        @include media-breakpoint-up(xs) {
            font-size: 12.5px;
        }
    
        @include media-breakpoint-up(sm) {
            font-size: $default-size;
        }
    }
}
