e[type="button"] {
    cursor: hand;
    cursor: pointer;
}
img {
    max-width: 100%;
    height: auto;
}
img[width], img[height] {
    max-width: 100% !important;
}
#content .row-gallery [style],
#content .row-gallery [width],
#content .row-gallery [height] {
  height: auto;
  float: none;
  margin: 0;
}

#contactmap, #contactmap[style] {
    width: 100% !important;
    height: 350px !important;
    margin-bottom: 40px !important;
}

.fullblock {
    float: left;
    width: 100%;
}

#blog {
    list-style: none;
    margin-left: 0;
}

#currencies {
    margin-bottom: 15px;
}

#currencies ul {
    margin-top: 10px;
}
#currencies ul li {
    font-size: 12px;
    display: inline;
    padding-right: 5px;
    list-style: none;
}

#currencies ul li a {
    white-space: nowrap;
    color: #505050;
}
#currencies ul li.selected a {
    text-decoration: none;
}


#all_brands {
    width: 220px;
    margin-bottom: 15px;
}

/* @group Brands */
#brands {
    clear: both;
    margin-top: 10px;
}
#brands a {
    color: #ec0060;
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 4px;
    padding-bottom: 6px;
    line-height: 1;
    display: inline-block;
}
#brands img {
    vertical-align: middle;
}
#brands a:hover {
    color: #000;
}
#brands a.selected {
    background-color: #ec0060;
    color: #fffeff;
    padding: 2px 2px 6px 2px;
}
/* @end */

/* @group Блог (Новости) */
#blog_menu {
    margin-bottom: 20px;
    margin-top: 10px;
}
#blog_menu ul {
    margin-bottom: 10px;
}

#blog li {
    margin-bottom: 20px;
}
/* @end */

/* Хлебные крошки */
#path {
    margin-bottom: 5px;
    font-size: 12px;
    color: #a0a0a0;
}
#path a {
    color: #a0a0a0;
}
/* @end */

/* Кнопка */
.button {
    float: left;
    clear: left;
    cursor: pointer;
}

.button {
    -moz-box-shadow: inset 0px 1px 0px 0px #ffffff;
    -webkit-box-shadow: inset 0px 1px 0px 0px #ffffff;
    box-shadow: inset 0px 1px 0px 0px #ffffff;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0.05, #ededed),
        color-stop(1, #dfdfdf)
    );
    background: -moz-linear-gradient(center top, #ededed 5%, #dfdfdf 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#dfdfdf');
    background-color: #ededed;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    border: 1px solid #cccccc;
    display: inline-block;
    color: #555555;
    font-family: arial;
    font-size: 14px;
    font-weight: bold;
    padding: 6px 20px;
    text-decoration: none;
    text-shadow: 1px 1px 0px #ffffff;
}
.button:hover {
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0.05, #dfdfdf),
        color-stop(1, #ededed)
    );
    background: -moz-linear-gradient(center top, #dfdfdf 5%, #ededed 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfdfdf', endColorstr='#ededed');
    background-color: #dfdfdf;
}
.button:active {
    position: relative;
    top: 1px;
}



/* @group Сортировка */
.sort {
    color: #505050;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: normal;
    font-style: normal;
}
.sort a {
    margin-right: 5px;
}
.sort .selected {
    color: #505050;
    text-decoration: none;
    margin-right: 5px;
}
/* @end */

/* @group Постраничная навигация */
.pagination {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    overflow: hidden;
}
.pagination a {
    display: block;
    float: left;
    background-color: #fffeff;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 7px 4px;
    min-width: 22px;
    text-align: center;
    border: 1px solid #d7d7d7;
    text-decoration: none;
}
.pagination a.selected:hover,
.pagination a.selected {
    background-color: #18a5ff;
    color: #ffffff;
    border-color: #008fe9;
}
.pagination a:hover {
    background-color: #f8f8f8;
}

.pagination a.next_page_link,
.pagination a.prev_page_link {
    border: none;
    background: none;
}
/* @end */

/* @group Просмотренные товары */
#browsed_products {
    margin-bottom: 20px;
    overflow: hidden;
    margin-left: 0;
}

#browsed_products li {
    display: block;
    float: left;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    margin: 0 7px 7px 0;
    width: 50px;
    height: 50px;
}
#browsed_products li a {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 50px;
    height: 50px;
}
/* @end — Просмотренные товары */



/* @group Комментарии */
.comment_list {
    margin-top: 15px;
    list-style: none;
}
.comment_list li {
    padding-bottom: 15px;
}
.comment_header {
    font-size: 18px;
}
.comment_header i {
    font-weight: normal;
    font-style: normal;
    color: #878787;
    font-size: 13px;
}
/* @end — Комментарии*/

/* @group Форма отправки комментария */
.comment_form {
    background-color: #f3f3f3;
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin-top: 20px;
    width: 90%;
    overflow: hidden;
}
.comment_form h2 {
    margin-bottom: 0px;
}
.comment_form .comment_textarea {
    width: 100%;
    height: 100px;
    font-size: 12px;
}
.comment_form label {
    display: block;
    float: left;
    width: 100px;
    font-size: 18px;
    margin-top: 15px;
}
.comment_form .input_name {
    font-size: 16px;
    width: 250px;
    margin-top: 15px;
}
.comment_form .input_captcha,
.cart_form .input_captcha {
    float: left;
    width: 150px;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 15px;
    height: 36px;
}
.comment_form .captcha,
.cart_form .captcha {
    float: left;
    display: block;
    margin-top: 15px;
    margin-right: 10px;
}
.comment_form .button,
.feedback_form .button,
.register_form .button,
.login_form .button {
    float: right;
    display: block;
    margin-top: 10px;
    margin-right: 0px;
}
/* @end — Форма отправки комментария */

/* @group Корзина */
#purchases {
    width: 100%;
}
#purchases tr {
    border-top: 1px solid #d5d5d5;
    height: 70px;
}
#purchases th {
    vertical-align: top;
    padding-top: 10px;
    font-size: 18px;
    font-weight: normal;
}
#purchases .image {
    width: 50px;
    text-align: center;
}
#purchases .image a {
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    margin: 0 7px 7px 0;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 50px;
    height: 50px;
}

#purchases .name {
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
}
#purchases td.name {
    font-size: 14px;
}
#purchases td .download_attachment {
    white-space: nowrap;
    color: #3b8500;
    padding: 5px 10px 7px 10px;
    border-bottom-color: 1px dotted green;
    background-color: #ccff72;
    line-height: 30px;
}

#purchases .price {
    padding-left: 5px;
    padding-right: 5px;
    white-space: nowrap;
    text-align: right;
}
#purchases td.price {
    font-size: 14px;
}
#purchases .remove {
    padding-left: 15px;
    text-align: right;
}
#purchases .amount {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
}
#purchases .amount select {
    font-size: 12px;
}

#purchases .coupon .name {
    font-size: 14px;
}
#purchases .coupon .name > div {
    margin-bottom: 10px;
}
#purchases .coupon .name input.coupon_code {
    width: 200px;
}
#purchases .coupon .name input {
    font-size: 16px;
}

.coupon [type="button"] {
    -webkit-appearance: push-button;
}

ul#deliveries {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    padding: 20px 20px 0 20px;
    list-style: none;
}
ul#deliveries li {
    margin-bottom: 20px;
}
ul#deliveries li div.checkbox {
    float: left;
}
ul#deliveries li h3,
ul#deliveries li .description {
    display: block;
    margin-left: 25px;
}
/* @end */

/* @group Кабинет */
#orders_history {
    list-style: none;
}
#orders_history li {
    margin-bottom: 10px;
}
#orders_history li a {
    font-size: 16px;
}
/* @end — Кабинет */

/* @group Детали заказа */
table.order_info {
    margin-right: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}
table.order_info td {
    padding: 10px;
    border: 1px dotted #e0e0e0;
    font-size: 14px;
}
.checkout_button {
    padding: 10px 20px 10px 20px;
    border: 1px solid #51a400;
    background-color: #d3ffa9;
    color: #2e5e00;
    font-size: 14px;
}
/* @end */

/* Сообщение с ошибкой */
.message_error {
    clear: both;
    height: 18px;
    padding: 10px 20px;
    margin-bottom: 15px;
    margin-top: 10px;
    overflow: hidden;
    color: red;
    background-color: #ffcaca;
    border: 1px dotted #ff4545;
}

/*Стили страницы*/
#content ul {
    margin-bottom: 15px;
}
#content ul li {
    list-style-position: inside;
}



/* @end */


/*Table in content*/
.maintable {
    border-collapse: collapse;
}
.maintable td {
    border: 1px solid #eeeeee;
    padding: 3px;
}

/* ----------  ----------- */

/* compare */
.product_compare_link {
    padding-top: 10px;
}
.product_compare_link input {
    visibility: hidden;
    position: absolute;
    margin-right: -800px;
}

/* --- DxLab --- Agent201 --- 2017-02-17 --- */
.group-link {
    display: inline-block;
    margin-top: 5px;
    margin-left: -145px;
}
.group-contact-link {
    display: inline-block;
    text-decoration: none;
    margin-left: 10px;
}
.group-contact-img {
    display: inline-block;
    position: relative;
    top: 1px;
    margin-right: 2px;
}

.zoom {
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 14px;
    display: flex;
    align-items: center;

    img {
        width: 100%;
        height: auto;
    }
}