.wrap-row {
    margin-left: -8px;
    margin-right: -8px;
}

.contact-wrap {
    display: flex;
    align-items: center;
    @media (min-width: 400px) {
        padding-left: 18vw;
        padding-right: 18vw;
    }
    @include media-breakpoint-up(lg) {
        justify-content: space-around;
        padding-left: 8vw;
        padding-right: 8vw;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
}
