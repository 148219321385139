
.catlist li {

    text-align: center;
}
.catlist li span {
    margin-top: 10px;
    clear: both;
    display: block;
}
