.menu {
    margin: 0;
    list-style: none;

    @include media-breakpoint-up(xs) {
        display: none;
    }

    @include media-breakpoint-up(md) {
        display: flex;
    }
}
.menu li:first-child {
    display: none;
}
.menu li a {
    margin-right: 5px;
    font-size: 12px;
    display: block;
    float: left;
    padding: 6px 6px 6px 6px;
    color: #606060;
}
.menu li.selected a,
.menu li:hover a {
    border: 1px solid #d5d5d5;
    background-color: #ffffff;
    padding: 5px 5px 5px 5px;
    border-radius: 20px;
    text-decoration: none;
}
