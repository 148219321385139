$padding: 8px;

.tiny_products {
    list-style: none;
}
.tiny_products .product {
    margin-bottom: 60px;
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-up(sm) {
        padding-left: $padding;
        padding-right: $padding;

        &:first-child {
            padding-left: 0;
        }

        &:nth-child(2n + 0) {
            padding-right: 0;
        }

        &:nth-child(2n + 1) {
            padding-left: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        &:first-child {
            padding-left: 0;
        }

        &:nth-child(2n + 0) {
            padding-left: $padding;
            padding-right: $padding;
        }

        &:nth-child(2n + 1) {
            padding-left: $padding;
        }

        &:nth-child(3n + 0) {
            padding-right: 0;
        }

        &:nth-child(3n + 1) {
            padding-left: 0;
        }
    }
}
.tiny_products .product .image {
    width: 100%;
    height: 200px;
    float: none;
    padding: 0;
    margin-right: 0;
    margin-bottom: 10px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
    position: relative;

    a {
        align-items: center;
        position: relative;
        overflow: hidden;
        display: grid;
    }
}

.tiny_products .product .image img {
    max-height: 100%;
    padding: 5px;
    border: none;
}
.tiny_products .product h3 {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: normal;
}
.tiny_products .product h3.featured {
    background: url(../images/star.png) no-repeat;
    background-position: left middle;
    padding-left: 20px;
}

.tiny_products .variants .table {
    margin-bottom: 5px;
}

.products {
    list-style: none;
    display: block;
}
.products .product {
    display: flex;
    margin-bottom: 30px;

    @include media-breakpoint-up (xs) {
        flex-direction: column;
        align-items: center;
    }
    @include media-breakpoint-up (sm) {
        flex-direction: row;
        align-items: normal;
    }
}
.products .product .image {
    text-align: center;
    flex: 0 0 200px;
    max-width: 200px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    float: none;
    padding: 0;

    align-self: flex-start;

    @include media-breakpoint-up (xs) {
        margin-bottom: 20px;
        margin-right: 0;
    }

    @include media-breakpoint-up (sm) {
        margin-bottom: 0;
        margin-right: 40px;
    }
}

.products .product .image img {
    //max-width: 200px;
    padding: 5px;
    border: none;
}
.products .product .product_info {

}
.products .product h3 {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: normal;
}
.products .product h3.featured {
    background: url(../images/star.png) no-repeat;
    background-position: left middle;
    padding-left: 20px;
}

.products .product .annotation {
    font-size: 12px;
    color: #505050;
    margin-bottom: 5px;
}


/* @group Товар подробно */
.product .image {
    padding: 0;
    text-align: center;
}
.product .image img {
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
}
.product .images {
    padding-top: 15px;
    padding-bottom: 15px;
}
.product .images a.zoom {
    justify-content: center;
    @include media-breakpoint-up(xs) {
        flex: 1 0 (100% / 2);
    }
    @media (min-width: 480px) {
        flex: 0 0 auto;
    }
}
.product .images img {
    flex: 0 0 auto;
    max-width: 50px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    padding: 5px;
}
.product .description {

}
.product .freedelivery {
    margin-top: 15px;
}
.product .features {
    list-style: none;
    width: 100%;
    display: block;
    clear: both;
    margin-top: 15px;
    margin-bottom: 25px;
    border-bottom: 1px solid #e0e0e0;
    margin-left: 0;
}
.product .features li {
    padding: 10px 5px 10px 5px;
    overflow: hidden;
    border-top: 1px solid #e0e0e0;
    background-color: #f0f0f0;
}
.product .features li.even {
    background-color: #e9e9e9;
}
.product .features label {
    font-style: normal;
    display: block;
    width: 30%;
    float: left;
    margin-right: 15px;
}
.product .features span {
    float: left;
    display: block;
    width: calc(70% - 15px);
    padding-left: 10px;
}
.product .variants {
    //float: left;
    font-size: 12px;
}
.product .variants table {
    margin-bottom: 7px;
}
.product .variant td {
    padding-bottom: 6px;
    vertical-align: middle;
}
.product .variants .button {
    margin-right: 10px;
}
.product .compare{
    margin-top: 10px;
    display: inline-block;
}
.product .compare_price {
    font-size: 14px;
    text-decoration: line-through;
    white-space: nowrap;
    color: #707070;
}
.product .price {
    font-size: 14px;
    white-space: nowrap;
}
.product .variant_name {
    float: left;
    margin-right: 10px;
    font-size: 12px;
}
.product .variant_radiobutton {
    margin-right: 5px;
    margin-left: 0px;
}
#back_forward {
    font-size: 14px;
    margin-bottom: 20px;
    clear: both;
    line-height: 1.3;
    
    .arrow {
        font-size: 1.6em;
    
        &--prev {
            padding-right: 15px;
        }

        &--next {
            padding-left: 15px;
        }
    }

    .page_link {
        display: block;
    }
}


