#footer {
    margin-top: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 1px solid #dddddd;
}

.footer {
    @include media-breakpoint-up(xs) {
        text-align: center;
    }

    @include media-breakpoint-up(lg) {
        text-align: left;
    }

    &__soc-network {
        margin-left: 15px;
        max-width: 20px;
    }

    &__left {
        @include media-breakpoint-up(xs) {
            margin-bottom: 20px;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    &__menu {
        display: flex;

        @include media-breakpoint-up(xs) {
            justify-content: center;
        }

        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
        }
    }
}

#menu2 {
    display: flex;
    list-style: none;
    margin: 0;

    @include media-breakpoint-up(xs) {
        flex-wrap: wrap;
    }

    @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
    }
}

#menu2 li {
    @include media-breakpoint-up(xs) {
        flex: 0 0 (100% / 3);
        max-width: (100% / 3);
        margin-bottom: 15px;
    }

    @include media-breakpoint-up(lg) {
        flex: 0 0 auto;
        max-width: initial;
        margin-bottom: 0;
    }
}

#menu2 li a {
    margin-right: 5px;
    font-size: 12px;
    padding: 6px 11px 6px 11px;
    color: #606060;
}

#menu2 li.selected a,
#menu2 li:hover a {
    border: 1px solid #d5d5d5;
    background-color: #ffffff;
    padding: 5px 10px 6px 10px;
    border-radius: 20px;
    text-decoration: none;
}
