/* @group Фильтр по свойствам */
#features {
    background-color: #f0f0f0;
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}
#features .feature_name {
    padding: 7px;
    font-weight: bold;

    @include media-breakpoint-up(md) {
        font-weight: normal;
    }
}
#features .feature_values {
    padding: 7px;
}
#features a {
    padding-right: 6px;
    white-space: nowrap;
}
#features a.selected {
    color: #505050;
    text-decoration: none;
}
/* @end — Фильтр по свойствам*/

.features__row {
    margin-left: 0;
    margin-right: 0;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 5px;
    padding-bottom: 5px;

    @include media-breakpoint-up(md) {
        border: none;
    }

    &:last-child {
        border: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
