.slider_block {
    
}
.slider {
    width: 100%;
    //margin: 10px 0 0;
}
.slider img {
    width: 100%;
    //max-height: 360px;
}
.slick-dots {
    width: 100%;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 15px;
    list-style: none;
    display: flex !important;
    justify-content: center;
}
.slick-dots button {
	font-size: 0;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: #cccccc;
    border: none;
    margin: 5px;
    cursor: pointer;
    padding: 0;
}
.slick-active button {
    background: #000000;
}