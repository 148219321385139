.header {
    padding-top: 15px;
    align-items: flex-end;

    @include media-breakpoint-up(md) {
        padding-bottom: 30px;
    }
}

.cam_cont {
    font-style: italic;
    color: #505050;
    font-size: 15px;
    padding-bottom: 4px;
    text-align: center;

    @include media-breakpoint-up(md) {
        margin-bottom: 10px;
        margin-bottom: 20px;
        padding-bottom: 0;
    }
}

.h_cart_into {
    text-align: right;
    line-height: 1;
}

#cart_informer {
    background-color: #fff;
    border-radius: 15px;
    color: #505050;
    margin-bottom: 10px;
}
#compare_informer {
    background-color: #fff;
    border-radius: 15px;
    color: #505050;
    margin-bottom: 10px;
}
#account {
    font-size: 12px;
    color: #505050;
}
#account #register {
    padding-right: 7px;
}
#account #login {
}
#account #logout {
}
