.user-content {
    h2 {
        @extend .headings-h2;
    }
    h3 {
        @extend .headings-h3;
    }
    p,
    ul {
        &:last-child {
            margin-bottom: 0;
        }
    }
    p + ul {
        margin-top: -24px;
    }
    strong {
        font-family: $font-family-bold;
    }
}
