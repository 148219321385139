/* @group Формы */
input[type="text"],
input[type="password"] {
    width: 100%;
    font-size: 18px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

textarea {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.form {
    max-width: 400px;
    margin-bottom: 20px;
}
.form label{
    margin-bottom: 10px;
}
.form textarea {
    width: 100%;
    height: 100px;
    font-size: 18px;
}
.form label {
    display: block;
    font-size: 14px;
}
.form input[type="text"] {
    display: block;
    margin-bottom: 10px;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* @group Форма отправки обратной связи */
.feedback_form {
    background-color: #f3f3f3;
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
.feedback_form .input_captcha,
.register_form .input_captcha {
    float: left;
    width: 150px;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 15px;
    height: 36px;
    border-radius: 0.25rem;
}
.feedback_form .captcha,
.register_form .captcha {
    float: left;
    display: block;
    margin-top: 15px;
    margin-right: 10px;
}
.feedback_form .button_send {
    font-size: 18px;
    float: right;
    margin-top: 25px;
}
/* @end — Форма отправки комментария */

.cart_form {
    width: 100%;
    max-width: 100%;

    .button {
        margin-top: 15px;
    }
}